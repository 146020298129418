import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TeamsService} from '../../services/teams/teams.service';
import {UsersService} from '../../services/users/users.service';

@Component({
  selector: 'app-team-member-selector',
  templateUrl: './team-member-selector.component.html',
  styleUrls: ['./team-member-selector.component.scss'],
})
export class TeamMemberSelectorComponent implements OnInit {

  @Input() teamId;
  @Output() membersSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('searchbar') searchbar;

  protected members;
  protected filteredMembers;

  constructor(
      private teamsService: TeamsService,
      private usersService: UsersService
  ) { }

  ngOnInit(
  ) {
    this.teamsService.getTeamInfo(this.teamId).then(team => {
      this.teamsService.getTeamMembers(this.teamId).then(members => {
        this.teamsService.buildMemberList(members, team);
        this.members = members.filter(member => member.message === 'Active' && member.userId !== this.usersService.getCurrentUserId());
        this.filteredMembers = JSON.parse(JSON.stringify(this.members));
      });
    });
  }

  private membersUpdated() {
    this.filteredMembers = JSON.parse(JSON.stringify(this.members.filter(member => !this.searchbar.value || member.userEmail.indexOf(this.searchbar.value) >= 0)));
    this.membersSelectedEvent.emit(this.members.filter(member => member.selected));
  }

  memberCheckboxClicked(filteredMember) {
    const member = this.members.find(thisMember => thisMember.email === filteredMember.email);
    member.selected = member.selected ? false : true;
    this.membersUpdated();
  }

  selectAll() {
    this.members.forEach(member => member.selected = true);
    this.membersUpdated();
  }

  deselectAll() {
    this.members.forEach(member => member.selected = false);
    this.membersUpdated();
  }

  filterItems() {
    this.membersUpdated();
  }

}
