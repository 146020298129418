import {EventEmitter, Injectable} from '@angular/core';
import {BrandsService} from '../brands/brands.service';
import {Restangular} from 'ngx-restangular';
import {BehaviorSubject} from 'rxjs';
import {WallitService} from '../wallit/wallit.service';
import { LiquidCache } from 'ngx-liquid-cache';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

    CACHE_TIME = 1000 * 60 * 60 * 12;

  public accountsObservable: BehaviorSubject<any>;
  private familyId: string;
  private userId: string;

  public transactionsObservable: BehaviorSubject<any>;
  private transactionsFamilyId: string;
  private transactionsUserId: string;

  private accountChangedEvent: EventEmitter<string> = new EventEmitter<string>();

  public transferHistoryReload = false;

  accounts: any;

  constructor(
      private brandsService: BrandsService,
      private restangular: Restangular,
  ) {
    this.accountsObservable = new BehaviorSubject(null);
    this.transactionsObservable = new BehaviorSubject(null);
  }

  reload() {
    this.accountsObservable.next(null);
    if (this.familyId && this.userId) {
      this.getPlaidDwollaAccounts();
    }
  }

    notifyAccountChanged(id: string) {
        this.accountChangedEvent.emit(id);
    }

    monitorAccountChanged(func) {
        this.accountChangedEvent.subscribe(value => func(value));
    }

    getSharedAccountsBalances(familyId: string, userId: string): Promise<any> {
      return this.restangular.one('v3').one('families', familyId).one('users', userId).one('sharedAccounts').
      all('balances').getList().toPromise();
  }

  getSharedAccountsInfo(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).one('sharedAccounts').get().toPromise();
  }

  reloadSharedAccount(familyId: string, userId: string, fundingSourceId: string, amount: number): Promise<any> {
      const data = {
        amount: amount,
        fundingSourceId: fundingSourceId
      };
      return this.restangular.one('v3').one('families', familyId).one('users', userId).one('sharedAccounts').
      one('reload').customPOST(data).toPromise();
  }

    private getFundingSources(familyId: string, userId: string): Promise<any> {
        return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
        one('dwolla').one('accounts').all('fundingSources').getList().toPromise();
    }

    // @LiquidCache('getAccountBalances{familyId}{userId}')
    getAccountBalances(familyId: string, userId: string): Promise<any> {
      return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
      one('plaid-dwolla').all('balances').getList().toPromise().then(result => {
          result.forEach(account => {
              if (!account.plaidAccount[0].balances.available) {
                  account.plaidAccount[0].balances.available = account.plaidAccount[0].balances.current;
              }
          })
          return result;
      });
    }

    updateServerBalanceCache(familyId: string, userId: string): Promise<any> {
        return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
            one('plaid').one('accounts').one('update-balance-cache').customPUT().toPromise();
    }

    getBanks(userId: string): Promise<[any]> {
    return this.restangular.one('users', userId).all('banks').getList({detailed: true}).toPromise();
  }

  addDwollaAccount(familyId: string, userId: string, data: any): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).
     one('banks').one('dwolla').all('accounts').customPOST(data).toPromise();
  }

  updateDwollaAccount(familyId: string, userId: string, data: any): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).one('banks').one('dwolla')
     .one('accounts').all('customData').customPATCH(data).toPromise();
  }

  removeAccount(familyId: string, userId: string, accountId: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).
     one('dwolla-plaid').one('fundingSources', accountId).remove().toPromise();
  }

  removePlaidAccount(familyId: string, userId: string, accessToken: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).
     one('plaid').one('access-tokens', accessToken).remove().toPromise();
    }

  uploadDwollaDocument(userId: string, bankId: string, image: any, docType: string): Promise<any> {
    return this.restangular.one('users', userId).one('banks', bankId).all('documents').
     customPOST(image, undefined, {documentType: docType}, { 'content-type': 'image/*' }).toPromise();
  }

  getDwollaDocumentStatus(userId: string): Promise<any> {
      return this.restangular.one('users', userId).one('banks', 'dwolla').all('documents').
      getList().toPromise();
  }

  getDwollaStatus(userId: string): Promise<any> {
    return this.restangular.one('users', userId).one('banks').one('dwolla').one('status').get().toPromise();
  }

  getDwollaBankBalances(familyId: string, userId: string): Promise<[any]> {
    this.familyId = familyId;
    this.userId = userId;
    return this.dwollarBalanceHelper();
  }

  private dwollarBalanceHelper(): Promise<any> {
    const _this = this;
    return this.restangular.one('families', this.familyId).one('banks').one('dwolla').
     all('balances').getList().toPromise().then((response) => {
      let data =  response.find(account => account.userId === this.userId).fundingSources;
      if (data) {
        data = data.filter(source => source.removed === false);
      }
      _this.accountsObservable.next(data);
      return data;
    });
  }

  getPendingFunds(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('families', familyId).one('banks').one('dwolla').all('balances').getList().toPromise().then((response) => {
      return response.find(account => account.userId === userId).pendingFunds;
    });

  }

  addFunds(familyId: string, userId: string, accountId: string, amount: number): Promise<any> {
    return this.restangular.one('users', userId).one('banks').one('dwolla').one('accounts').
     all('reload').customPOST({ destinationUserId: userId, fundingSourceId: accountId, amount: amount}).toPromise();
  }

  withdrawFunds(userId: string, accountId: string, amount: number): Promise<any> {
      return this.restangular.one('v3').one('users', userId).one('banks').one('dwolla').all('withdraws').
      customPOST({ destinationFundingSourceId:  accountId, amount: amount }).toPromise();
  }

  setPlaidToken(familyId: string, userId: string, token: String, metadata: any): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
     one('plaid').all('access-token').customPOST({ public_token: token, metadata: metadata }).toPromise();
  }

  getPlaidTokens(familyId: string, userId: string): Promise<any> {
      return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
      one('plaid').one('accounts').all('public-tokens').getList().toPromise();
  }

  getPublicToken(accessToken: string): Promise<any> {
      return this.restangular.one('plaid').one('public-token-from-access-token', accessToken).customPOST().toPromise();
  }

  getLinkToken(options: any): Promise<any> {
      return this.restangular.one('plaid').one('createLinkToken').customPOST(options).toPromise();
  }

  getAccessTokens(familyId: string, userId: string): Promise<any> {
      return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
          one('plaid').one('accounts').all('access-tokens').getList().toPromise();
  }

  // @LiquidCache('getAccessTokenStatus{familyId}{userId}{accessToken}')
  getAccessTokenStatus(familyId: string, userId: string, accessToken: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
    one('plaid').one('accessTokens', accessToken).one('status').get().toPromise();
  }

  getPlaidAccountsForInstitution(familyId: string, userId: string, accessToken: string): Promise<any> {
      return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
       one('plaid', accessToken).one('auth').get().toPromise().then(data => {
        return data.auth.accounts;
      });
  }

  // @LiquidCache('getPlaidAccountInfo{familyId}{userId}{bankId}')
  getPlaidAccountInfo(familyId: string, userId: string, bankId: string): Promise<any> {
      return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
      one('plaid-dwolla', bankId).all('balances').getList().toPromise().then(data => {
          return data.length > 0 ? data[0] : undefined;
      });
  }

  // @LiquidCache('getPlaidTransactions{familyId}{userId}{accountId}')
  getPlaidTransactions(familyId: string, userId: string, accountId: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
     one('plaid', accountId).one('transactions').get().toPromise().then(data => {
         return data.transactions;
    });
  }

  addPlaidAccountToDwolla(familyId: string, userId: string, accountId: string, name: string, accessToken: string, accountType: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').
     one('plaid', accessToken).all('processor-token').customPOST({ 'bank_id': 'dwolla', account_id: accountId, account_type: accountType}).toPromise().then(data => {
      if (accountType === 'credit') {
          return({dwolla_funding_source: 'credit-' + accountId });
      }
      return this.restangular.one('v3').one('families', familyId).one('users', userId).one('banks').one('plaid-dwolla', accessToken).
       all('fundingSource').customPOST({ 'funding_source_name': name, processor_token: data.processor_token }).toPromise();
    });
  }

  subscribePlaidDwollaAccounts(familyId: string, userId: string, response) {
    this.accountsObservable.subscribe(response);
    if (!this.familyId) {
        this.familyId = familyId;
        this.userId = userId;
        this.getPlaidDwollaAccounts();
    }
  }

    subscribeActivePlaidDwollaAccounts(familyId: string, userId: string, response) {
      this.subscribePlaidDwollaAccounts(familyId, userId, accounts => {
          response(accounts.filter(account => account.fundingSourceType === 'bank'));
      });
    }

  getPlaidDwollaAccounts(familyId = this.familyId, userId = this.userId): Promise<any> {
      return this.getFundingSources(familyId, userId).then(data => {
          this.accountsObservable.next(data);
          return data;
      }).catch(error => {
          this.accountsObservable.next([]);
      });
  }

  subscribePlaidDwollaTransactions(familyId: string, userId: string, response) {
      this.transactionsObservable.subscribe(response);
      if (!this.transactionsFamilyId) {
          this.transactionsFamilyId = familyId;
          this.transactionsUserId = userId;
          this.getPlaidDwollaTransactions();
      }
  }

  // @LiquidCache('getPlaidDwollaTransactions{familyId}{userId}')
  getPlaidDwollaTransactions(familyId = this.transactionsFamilyId, userId = this.transactionsUserId): Promise<any> {
      return this.restangular.one('families', familyId).one('users', userId).
       one('banks').one('dwolla').one('accounts').all('transfers').getList().toPromise().then(data => {
           this.transactionsObservable.next(data);
           return data;
      });
  }

  getAccountList(familyId: string, userId: string): Promise<any> {
      return this.restangular.one('v3').one('families', familyId).one('users', userId).all('bank-accounts').getList().toPromise();
  }

  addJointAccounts(familyId: string, userId: string, accounts: any) {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).
     one('accountSharing').customPUT(accounts).toPromise();
  }

  getJointAccounts(familyId: string, userId: string) {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).all('accountSharing').getList().toPromise();
  }

  depositToAccount(userId: string, fundingSourceId: string, amount: number) {
    return this.restangular.one('users', userId).one('banks').one('dwolla').one('accounts').
     one('reload').customPOST({ fundingSourceId: fundingSourceId, amount: amount}).toPromise();
  }

  cancelBankTransfer(familyId: string, userId: string, transferId: string) {
      return this.restangular.one('families', familyId).one('users', userId).one('banks', 'dwolla').one('accounts').
       one('transfers', transferId).one('status').one('cancel').put().toPromise();
  }

  reloadTransferHistory() {
      if (this.transactionsFamilyId && this.transactionsUserId) {
          this.getPlaidDwollaTransactions();
      }
  }

  // @LiquidCache('getSmartBalances{familyId}{userId}')
  getSmartBalances(familyId: string, userId: string): Promise<any> {
      return this.restangular.one('v4').one('families', familyId).one('users', userId).one('plaid').one('smart-balances').getList().toPromise();
  }

  getTransactions(familyId: string, userId: string): Promise<any> {
        return this.restangular.one('v4').one('families', familyId).one('users', userId).one('transactions').customGETLIST('', {limit: 1000}).toPromise();
  }

  // @LiquidCache('getInstitutionInfo{name}')
  getInstitutionInfo(name: string): Promise<any> {
      return this.restangular.one('plaid').one('institutions').one('search').get({ q: name}).toPromise().then(results => {
          return results.find(result => result.name === name);
      });
  }
    // @LiquidCache('getInstitutionById{id}')
    getInstitutionById(id: string): Promise<any> {
        return this.restangular.one('plaid').one('institutions').one('getbyid').get({ q: name}).toPromise().then(results => {
            return results.find(result => result.name === name);
        });
    }

    setAchTestMode(userId: string, bankId: string, mode: string): Promise<any> {
        return this.restangular.one('users', userId).one('banks', bankId).one('accounts/fundingSource/settestmode', mode).put().toPromise();
    }

}

