import {AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import createHTMLMapMarker from './html-map-marker';
import {ActivatedRoute, Router} from '@angular/router';
import {RebatesService} from '../../services/rebates/rebates.service';
import {LocationService} from '../../services/location/location.service';
import {CommissionJunctionService} from '../../services/commissionjunction/commissionjunction.service';

declare var google;

@Component({
  selector: 'app-rebates-map',
  templateUrl: './rebates-map.component.html',
  styleUrls: ['./rebates-map.component.scss'],
})
export class RebatesMapComponent implements OnInit, AfterContentInit {

  @Input() clo;
  @Input() noUI = false;
  @Input() position;
  @Input() categorySlug;
  @Input() title;
  @Output() mapPosition: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild('mapElement') mapElement: ElementRef;

  private map;
  protected rebates = [];
  private currentLocation;
  private markersArray = [];
  private currentLocationMarker;
  private locationSubscription;

  protected tooltipOptions = {
    'tooltip-class': 'tooltipClass'
  }

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private rebatesService: RebatesService,
      private locationService: LocationService,
      private commissionJunctionService: CommissionJunctionService
  ) {
  }

  ngOnInit() {
  }

  private setLocationToHere() {
    this.map.setCenter({ lat: this.currentLocation.latitude, lng: this.currentLocation.longitude});
  }

  private clearAllMarkers() {
    while (this.markersArray.length) {
      this.markersArray.pop().setMap(null);
    }
  }

  private getSearchRadiusInMiles() {
    const metersPerPx = 156543.03392 * Math.cos(this.map.getCenter().lat() * Math.PI / 180) / Math.pow(2, this.map.getZoom());
    return 0.45 * this.mapElement.nativeElement.clientWidth * metersPerPx / 1609.34;
  }

  private async loadAllMarkers(latLong) {
    const loadRebates = (rebates) => {
      this.rebates = rebates;
      rebates.slice().reverse().forEach(rebate => {
        const classSuffix = rebate.type === 'groupon' ? '-groupon' : '';
        const typeImage = rebate.type === 'groupon' ? '/assets/icon/discount.png' : '/assets/icon/cashback.png';
        const percentage = rebate.type === 'groupon' ? rebate.discount_percentage : rebate.cashback_percentage;
        const markerInfo = rebate.type === 'access' ? `$${parseFloat(rebate.discount_value).toFixed(2)}` : `${Math.floor(percentage * 100)}%`;
        const newMarker = createHTMLMapMarker({
          latlng: new google.maps.LatLng(rebate.merchant.latitude, rebate.merchant.longitude),
          map: this.map,
          html: `<div class="marker-outer${classSuffix}"><img class="marker-image" src="${rebate.image_url}"><div class="marker-text${classSuffix}">${markerInfo}<img class="marker-type-image" src="${typeImage}"></div></div>`
        });
        this.markersArray.push(newMarker);
        if (!this.noUI) {
          newMarker.addListener('click', () => {
            this.router.navigate(['rebatedetail', rebate.type, rebate.id]);
          });
        }
      });
      // if (this.locationSubscription) {
      //  this.locationSubscription.unsubscribe();
      // }
      this.locationSubscription = this.locationService.hereSubscribe(location => {
        if (location) {
          if (this.currentLocationMarker) {
            this.currentLocationMarker.remove();
          }
          this.currentLocationMarker = createHTMLMapMarker({
            latlng: new google.maps.LatLng(location.coords.latitude, location.coords.longitude),
            map: this.map,
            html: `<div class="marker-blue"></div>`
          });
        }
      });
    };
    if (this.clo) {
      const deal = await this.commissionJunctionService.getCLODetails(this.clo);
      const bottomRightPosition = { latitude: 90, longitude: 180 };
      const topLeftPosition = { latitude: -90, longitude: -180 };
      const rebates =  deal.locations.map((location, locationIndex) => {
        if (location.latitude > topLeftPosition.latitude) {
          topLeftPosition.latitude = parseFloat(location.latitude);
        }
        if (location.latitude < bottomRightPosition.latitude) {
          bottomRightPosition.latitude = parseFloat(location.latitude);
        }
        if (location.longitude > topLeftPosition.longitude) {
          topLeftPosition.longitude = parseFloat(location.longitude);
        }
        if (location.longitude < bottomRightPosition.longitude) {
          bottomRightPosition.longitude = parseFloat(location.longitude);
        }
        return {
          index: locationIndex,
          type: 'clo',
          id: deal.id,
          _raw: deal,
          title: deal.title,
          short_title: deal.title,
          locations: deal.locations,
          cashback_percentage: deal.cashBackPercentage / 100,
          image_url: deal['image-url'],
          merchant: {
            name: deal['advertiser-name'],
            latitude: location.latitude,
            longitude: location.longitude
          }
        };
      });
      loadRebates(rebates);
      const radius = Math.sqrt((topLeftPosition.latitude - bottomRightPosition.latitude) ** 2 + (topLeftPosition.longitude - bottomRightPosition.longitude) ** 2) * 69 * .45;
      const center = { lat: (topLeftPosition.latitude + bottomRightPosition.latitude) / 2, lng: (topLeftPosition.longitude + bottomRightPosition.longitude) / 2};
      this.map.setCenter(center);
      this.map.setZoom(11 - Math.log2(radius / 6));
      console.log('topleft', topLeftPosition, 'botright', bottomRightPosition)
      console.log('radius', radius, 'center', center)
    } else {
      this.rebatesService.getRebatesByCategoryAndLocation(this.categorySlug, latLong.latitude, latLong.longitude, this.getSearchRadiusInMiles()).then(rebates => {
        loadRebates(rebates);
      });
    }
  }

  ngAfterContentInit() {
    setTimeout(() => {
      if (this.position) {
        this.initializeMap({latitude: parseFloat(this.position.latitude), longitude: parseFloat(this.position.longitude), radius: parseFloat(this.position.radius)});
      } else {
        this.locationService.here().then(location => {
          this.initializeMap(location);
        });
      }
    }, 500);
  }

  initializeMap(loc) {
    let zoom = 11;
    this.currentLocation = loc;
    if (loc.radius) {
      zoom = zoom - Math.log2(loc.radius / 6);
    }
    let options = {
      center: { lat: this.currentLocation.latitude, lng: this.currentLocation.longitude },
      zoom: zoom,
      streetViewControl: false
    };
    const noUIoptions = {
      disableDefaultUI: true,
      zoomControl: false,
      gestureHandling: 'none'
    };
    if (this.noUI) {
      options = {...options, ...noUIoptions};
    }
    this.map = new google.maps.Map(this.mapElement.nativeElement, options);
   const _this = this;
    function MakeTextControl(controlDiv, innerHTML) {
      // Set CSS for the control border.
      const controlUI = document.createElement('div');
      controlUI.style.backgroundColor = window.getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary');
      // controlUI.style.border = '2px solid #fff';
      controlUI.style.borderRadius = '1px';
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      controlUI.style.cursor = 'pointer';
      controlUI.style.marginBottom = '22px';
      controlUI.style.marginLeft = '-70px';
      controlUI.style.marginRight = '30px';
      controlUI.style.textAlign = 'center';
      controlUI.style.borderRadius = '2px';
      // controlUI.title = 'Click to recenter the map';
      controlDiv.appendChild(controlUI);
      // Set CSS for the control interior.
      const controlText = document.createElement('div');
      controlText.style.color = 'white';
      controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlText.style.fontSize = '16px';
      controlText.style.lineHeight = '38px';
      controlText.style.paddingLeft = '5px';
      controlText.style.paddingRight = '5px';
      controlText.style.left = '8px';
      controlText.innerHTML = innerHTML;
      controlUI.appendChild(controlText);
      return controlUI;
    }
    function MakeImageControl(controlDiv, innerHTML) {
      // Set CSS for the control border.
      const controlUI = document.createElement('div');
      controlUI.style.backgroundColor = '#fff';
      // controlUI.style.border = '2px solid #fff';
      controlUI.style.borderRadius = '1px';
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      controlUI.style.cursor = 'pointer';
      controlUI.style.marginBottom = '22px';
      controlUI.style.marginLeft = '-70px';
      controlUI.style.marginRight = '30px';
      controlUI.style.textAlign = 'center';
      // controlUI.title = 'Click to recenter the map';
      controlDiv.appendChild(controlUI);
      // Set CSS for the control interior.
      const controlText = document.createElement('div');
      controlText.style.color = 'rgb(25,25,25)';
      controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlText.style.fontSize = '16px';
      controlText.style.paddingTop = '9px';
      controlText.style.paddingBottom = '5px';
      controlText.style.paddingLeft = '5px';
      controlText.style.paddingRight = '5px';
      controlText.style.left = '8px';
      controlText.innerHTML = innerHTML;
      controlUI.appendChild(controlText);
      return controlUI;
    }
    const centerControlDiv = document.createElement('div');
    const centerControl = MakeImageControl(centerControlDiv, '<img width="24px" src="/assets/icon/locate.png">');
    centerControl.addEventListener('click', function(e) {
      e.stopPropagation();
      _this.locationService.here().then(location => {
        _this.currentLocation = location;
        _this.map.setCenter({ lat: _this.currentLocation.latitude, lng: _this.currentLocation.longitude});
        _this.loadAllMarkers(_this.currentLocation);
      });
    });
    if (!this.noUI) {
      this.map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(centerControlDiv);
    }
    const searchControlDiv = document.createElement('div');
    const searchControl = MakeTextControl(searchControlDiv, 'Search this Area');
    searchControl.addEventListener('click', function(e) {
      e.stopPropagation();
      _this.clearAllMarkers();
      const mapCenter = _this.map.getCenter();
      _this.loadAllMarkers({latitude: mapCenter.lat(), longitude: mapCenter.lng()});
    });
    if (!this.noUI) {
      this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(searchControlDiv);
    }
    this.map.addListener('zoom_changed', function(e) {
      _this.updateBounds();
    });
    this.map.addListener('center_changed', function(e) {
      _this.updateBounds();
    });
    this.loadAllMarkers(this.currentLocation);
    setTimeout(() => {
      _this.updateBounds();
    }, 500);
  }

  updateBounds() {
    const location = this.map.getCenter();
    this.mapPosition.emit({latitude: location.lat(), longitude: location.lng(), radius: this.getSearchRadiusInMiles() });
  }

  mapClicked() {
    if (this.noUI) {
      const location = this.map.getCenter();
      this.router.navigate(['/rebateslocal', location.lat(), location.lng(), this.getSearchRadiusInMiles()], {replaceUrl: true});
    }
  }

  showAllClicked() {
      if (this.noUI) {
          const location = this.map.getCenter();
          this.router.navigate(['/rebateslocal', location.lat(), location.lng(), this.getSearchRadiusInMiles()]);
      }
  }

}
