import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppSettings} from '../../app.settings';
import {WallitService} from '../../services/wallit/wallit.service';
import {TeamsService} from '../../services/teams/teams.service';
import {UsersService} from '../../services/users/users.service';
import {BrandsService} from '../../services/brands/brands.service';

@Component({
  selector: 'app-user-carousel',
  templateUrl: './user-carousel.component.html',
  styleUrls: ['./user-carousel.component.scss']
})
export class OurlyUserCarouselComponent implements OnInit  {

  @Input() info;
  @Input() name = 'chart';
  @Input() carouselUpdate;

  @ViewChild('Slides') slides;

  protected goals = [];
  protected showSpendingChart = this.brandsService.getUIConfig().spendingChart;
  protected userId;
  protected wallit;
  protected earnKepUserId;
  protected earnKepFamilyId;
  protected smartBalances;

  private familyId;
  protected isBeginning;
  protected isEnd;
  public teams = [];

  private slideOpts = {
    spaceBetween: 10,
    watchOverflow: false,
    pagination: {
      type: 'bullets',
      clickable: true,
      loop: false,
      el: '.swiper-pagination',
      /*renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
        },*/
    }
  };

  constructor(
      private wallitService: WallitService,
      private teamsService: TeamsService,
      private usersService: UsersService,
      private brandsService: BrandsService
  ) { }

  ngOnInit() {
    this.wallitService.monitorWallitChanged(wallit => {
      if (wallit) {
        this.smartBalances = wallit.smartBalances.map(balance => {
          const balanceAccount = wallit.accounts.find(account => account.fundingSourceType !== 'balance' && account.metadata.account.id === balance.plaidAccountId);
          return {
            bankId: balanceAccount.counterParty?.fundingSourceId,
            account_id: balanceAccount.counterParty?.plaidAccountId,
            balance: balance.availableBalance,
            accountName: `${balance.thisAccount.name} ****${balanceAccount.metadata.account.mask}`,
            accountLogo: balanceAccount.metadata.institution.logo,
            accountDetails: balanceAccount.metadata.institution.name,
            left: {
              amount: balance.thisAccount.balances.current,
              label: 'Current Balance'
            },
            right: {
              amount: balance.smartBalance,
              label: 'Smart Balance',
            }
          };
        });
        if (this.smartBalances.length === 0) {
          this.slideOpts = Object.assign(this.slideOpts, {pagination: null});
        }
        this.smartBalances.unshift({
          balance: wallit.wallitBalance,
          accountName: 'Wallit Balance',
          accountLogo: undefined,
          accountDetails: 'Available to transfer',
          left: {
            amount: wallit.pendingInboundAmount - wallit.pendingOutboundAmount,
            label: 'Pending Transfers'
          },
          right: {
            amount: wallit.cashback.ytd,
            label: 'YTD Wallit Dollars',
          }
        });
        console.log('BALANCES', this.smartBalances)
        this.setPagerDots();
        setTimeout(() => {
          this.slides.update();
        }, 50);
      }
    });
    /*
    this.teamsService.getUserMemberTeams(this.usersService.getCurrentUserId()).then(teams => {
      this.teams = teams;
      this.setPagerDots();
    });
     */
  }

  private setPagerDots() {
    document.documentElement.style.setProperty('--swipervisibility',  this.teams.length === 0 ? 'none' : 'block');
  }

  leftArrow() {
    this.slides.slideNext();
  }

  rightArrow() {
    this.slides.slidePrev();
  }

  updateSliderIconState() {
    this.isEnd = this.slides.isEnd().then(isEnd => {
      this.isEnd = isEnd;
    });
    this.slides.isBeginning().then(isBeginning => {
      this.isBeginning = isBeginning;
    });
  }

}

