import { Injectable } from '@angular/core';
import {AppSettings} from '../../app.settings';
import {BrandsService} from '../brands/brands.service';

@Injectable({
  providedIn: 'root'
})
export class LinksService {

  constructor(
      private brandsService: BrandsService
  ) { }

  supportLink() {
    return AppSettings.HELP_ENDPOINT + this.getBrandedPath('faq');
  }

  termsLink() {
    // return 'https://www.ourly.com/terms-conditions';
    return AppSettings.HELP_ENDPOINT + this.getBrandedPath('disclosures#terms');
  }

  privacyLink() {
    // return 'https://www.ourly.com/privacy-policy';
    return AppSettings.HELP_ENDPOINT + this.getBrandedPath('disclosures#privacy');
  }

  helpLink() {
    return AppSettings.HELP_ENDPOINT;
  }

  dwollaTermsLink() {
    return 'https://www.dwolla.com/legal/tos/?whitelabel';
  }

  dwollaPrivacyLink() {
    return 'https://www.dwolla.com/legal/privacy';
  }

  private getBrandedPath(name: string): string {
    return name;
    /*
    const subDomain = this.brandsService.getBrandSubdomain();
    if (subDomain) {
      return 'partner/' + this.brandsService.getBrandSubdomain() + '-' + name + '?brand=' + this.brandsService.getBrandSubdomain();
    } else {
      return 'partner/' + name;
    }
    */
  }

  getHelpHref(helpId: string): string {
    return AppSettings.HELP_ENDPOINT + (helpId === '#' ? '' : 'pages/' + helpId + '?brand=' + this.brandsService.getBrandSubdomain());
  }

}
