import { Injectable } from '@angular/core';
import { ApiService} from '../api/api.service';
import {Restangular} from 'ngx-restangular';
import { JwtHelperService } from '@auth0/angular-jwt';
import {UsersService} from '../users/users.service';
import {AccountsService} from '../accounts/accounts.service';
import {TasksService} from '../tasks/tasks.service';
import {GoalsService} from '../goals/goals.service';
import * as moment from 'moment/moment';
import {LocalStorageServiceProvider} from '../local-storage-service/local-storage-service';
import {APP_VERSION} from '../../version.js';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public signupMode = false;
  public invitationMode = false;
  public loginUrl;

  JWT_TOKEN = 'JWTToken';
  REFRESH_TOKEN = 'refreshToken';

  constructor(
      private apiService: ApiService,
      private localStorageService: LocalStorageServiceProvider,
      private restangular: Restangular,
      private usersService: UsersService,
      private accountsService: AccountsService,
      private tasksService: TasksService,
      private goalsService: GoalsService,
  ) {
    const _this = this;
    function checkApi() {
      return _this.restangular.one('health-check').get().toPromise().then((data) => {
        ApiService.hideWaiting();
      }).catch(error => {
        if (error.status !== 0) {
          // not a network down error
          ApiService.showWaiting('Oops! Looks like we are experiencing some issues. We’re working to solve the problem ASAP. Please check back soon!');
        }
      });
    }
    /*
    checkApi();
    setInterval(checkApi, 60000);
     */
  }

  isAppUpToDate(): Promise<any> {
    return this.restangular.one('health-check').get().toPromise().then(data => {
      return data.appVersion === APP_VERSION;
    });
  }

  login(user): Promise<any> {
    return this.restangular.all('auth').post(user).toPromise().then((data) => {
      this.setToken(data.id, data.refreshToken);
      return data;
    });
  }

  loginUserPassword(user): Promise<any> {
    return this.restangular.one('auth').all('user-password').post(user).toPromise().then((data) => {
      this.parseTokens(data);
      return data;
    });
  }

  linkChildSignup(user: any, invite: string): Promise<any> {
    return this.restangular.one('auth').all('user-password').post(user, {invite: invite}).toPromise().then((data) => {
      this.parseTokens(data);
      return data;
    });
  }

  linkChildSMSSignup(user: any, invite: string): Promise<any> {
    return this.restangular.one('auth').all('mobile').post(user, {invite: invite}).toPromise().then((data) => {
      return data;
    });
  }

  private getAge(dateString): number {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  private setPermissions(user) {
    user.permissionLevel = !user.birthdate || this.getAge(user.birthdate) >= 18 ? 8 : 1;
  }

  createAccount(user): Promise<any> {
    this.setPermissions(user);
    return this.restangular.one('auth').all('create-user-password').post(user).toPromise().then((data) => {
      this.setToken(data.id, data.refreshToken);
      return data;
    });
  }

  createSMS(user): Promise<any> {
    user.firstName = '-';
    user.lastName = '-';
    this.setPermissions(user);
    return this.restangular.all('auth').all('mobile').post(user).toPromise();
  }

   loginSMS(number: string): Promise<any> {
    return this.restangular.all('auth').one('mobile').post(number, {}).toPromise();
  }

  resendSMS(number: string): Promise<any> {
    return this.restangular.all('auth').one('mobile', number).customPOST({}).toPromise();
  }

  deletePhoneNumber(number: string): Promise<any> {
    return this.restangular.one('mobile', number).remove().toPromise();
  }

  public loginValidateCode(phoneNumber: string, code: string): Promise<any> {
    return this.restangular.all('auth').one('mobile', phoneNumber).one('verificationCode', code).get().toPromise().then((data) => {
      this.parseTokens(data);
      return data;
    });
  }

  setTimeZone(userId: string) {
    const timezone = moment(moment.utc().toDate()).local().format('Z');
    return this.restangular.one('users', userId).one('notifications-settings').one('timezone', timezone).customPUT().toPromise();
  }

  logout() {
    this.apiService.unsetToken();
    this.localStorageService.removeStorage(this.JWT_TOKEN);
    this.localStorageService.removeStorage(this.REFRESH_TOKEN);
  }

  public setToken(token, refreshToken = null) {
    this.apiService.setToken(token);
    this.localStorageService.set(this.JWT_TOKEN, token);
    if (refreshToken) {
      this.localStorageService.set(this.REFRESH_TOKEN, refreshToken);
    }
    this.usersService.reloadMe();
    this.tasksService.reload();
    this.goalsService.reload();
    this.accountsService.reload();
  }

  public refreshToken() {
    const data = {
      refreshToken: this.localStorageService.get(this.REFRESH_TOKEN)
    };
    return this.restangular.one('auth').all('refresh').post(data).toPromise().then((response) => {
      this.setToken(response.id, response.refreshToken);
      return data;
    });
  }

  private parseTokens(data) {
    const parts = data.url.split('/').slice(-2);
    this.setToken(parts[0], parts[1]);
  }

  public decodeJWTToken(jwt = this.localStorageService.get('JWTToken')) {
    try {
      const helper = new JwtHelperService();
      return helper.decodeToken(jwt);
    } catch (err) {
      return null;
    }
  }

  public getUserId() {
    const token = this.decodeJWTToken();
    return token ? token.userId : null;
  }

  public getTokens(): Array<string> {
    return [this.localStorageService.get(this.JWT_TOKEN), this.localStorageService.get(this.REFRESH_TOKEN)];
  }

}
