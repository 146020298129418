import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  constructor(
      private restangular: Restangular
  ) {
  }

  getProductLaunchUrl(vendor, product, context): Promise<any> {
    return this.restangular.one('vendors', vendor).one('launchurl', product).customPUT(context).toPromise();
  }

}
