import {EventEmitter, Injectable} from '@angular/core';
import {UsersService} from '../users/users.service';
import {AccountsService} from '../accounts/accounts.service';
import {SmartbalanceService} from '../smartbalance/smartbalance.service';
import {BrandsService} from '../brands/brands.service';

// user banking state:
//
// interface Account {
//    id: string
//    balance : {
//      current: number;
//      available: number;
//    }
//    state: enum { Verified, Microdeposits pending, NeedReverification }
//    transactions: Array<Transaction>
//    smartBalance: {
//      current: number
//      repeatingInboundAmount: number
//      repeatingOutboundAmount: number
//      startDate: Timestamp
//      endDate: Timestamp
//      repeatingTransactions: Array<Transaction>
//    }
// }
// interface wallit {
//    accounts: Array<Account>
//    wallit: {
//      balance: number
//      pendingInbound: number
//      pendingOutbound:
//    }
//    cashback: {
//      ytd: number
//      lifetime: number
// }

@Injectable({
  providedIn: 'root'
})
export class WallitService {

  private empty_wallit = {
    accounts: [],
    smartBalances: [],
    pendingInboundAmount: 0,
    pendingOutboundAmount: 0,
    wallitBalance: 0,
    totalBalance : 0,
    cashback: {
      ytd: 0,
      lifetime: 0
    }
  };

  public wallit = this.empty_wallit;

  private gettingWallit = false;
  private wallitChangedEvent: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
      private usersService: UsersService,
      private accountsService: AccountsService,
      private smartBalanceService: SmartbalanceService,
      private accountService: AccountsService,
      private brandsService: BrandsService,
  ) {
    this.usersService.meSubscribe(me => {
      if (me) {
        this.wallit.cashback = me.cashback;
        this.getWallit();
      } else {
        this.wallit = this.empty_wallit;
        this.wallitChangedEvent.emit(this.wallit);
      }
    });
  }

  monitorWallitChanged(func) {
    this.wallitChangedEvent.subscribe(value => func(value));
    func(this.wallit);
  }

  triggerWallitChanged() {
    this.wallitChangedEvent.emit(this.wallit);
  }

  getWallit() {
    if (!this.gettingWallit) {
      this.gettingWallit = true;
      this.usersService.myWallit().then(wallit => {
        this.gettingWallit = false;
        console.log('MY WALLIT', wallit);
        this.wallit = wallit;
        this.wallitChangedEvent.emit(this.wallit);
      }).catch(error => {
        this.gettingWallit = false;
      });
    }
  }

  counterParties() {
   return this.wallit.accounts.find(account => account.counterParty).map(account => account.counterParty);
  }

  isRadius() {
    return this.brandsService.getUIConfig().bank === 'radius';
  }

}
