import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RebatesService {

  private cachedCategories;
  private cachedOffers = [];
  private GROUPON_COMMISSION_RATIO = 0.1;
  private USER_CASHBACK_RATIO = 0.5;

  private GROUPON = false;
  private ACCESS = true;

  constructor(
      private restangular: Restangular,
      private httpClient: HttpClient
  ) {

  }

  getCategory(slug): Promise<any> {
    return this.getCategories().then(result => {
      return result.find(category => category.slug === parseInt(slug, 10));
    });
  }

  getCategories(): Promise<any> {
    if (this.cachedCategories) {
      return Promise.resolve(this.cachedCategories.categories);
    }
    return this.restangular.one('v3').one('rebates').one('categories').get().toPromise().then(results => {
      this.cachedCategories = results;
      return results.categories;
    });
  }

  getTeamMap(): Promise<any> {
    if (this.cachedCategories) {
      return Promise.resolve(this.cachedCategories.teamMap);
    }
    return this.restangular.one('v3').one('rebates').one('categories').get().toPromise().then(results => {
      this.cachedCategories = results;
      return results.teamMap;
    });
  }

  private reformatResults(results) {
    if (this.GROUPON) {
      const offers = results.map((deal, index) => {
        if (!deal) {
          return undefined;
        }
        if ('preferredOfferIndex' in deal) {
          const preferredOption = deal.options[deal.preferredOfferIndex];
          return {
            type: 'groupon',
            id: preferredOption.id,
            _raw: deal,
            title: deal.title,
            short_title: deal.title,
            discount_percentage: preferredOption.discountPercent / 100,
            cashback_percentage: this.GROUPON_COMMISSION_RATIO * this.USER_CASHBACK_RATIO,
            price: preferredOption.price.amount / 100,
            url: preferredOption.buyUrl,
            image_url: deal.largeImageUrl,
            merchant: {
              name: deal.merchant.name,
              latitude: preferredOption.redemptionLocationCount > deal.preferredLocationIndex ? preferredOption.redemptionLocations[deal.preferredLocationIndex].lat : deal.division.lat,
              longitude: preferredOption.redemptionLocationCount > deal.preferredLocationIndex ? preferredOption.redemptionLocations[deal.preferredLocationIndex].lng : deal.division.lng
            }
          };
        } else {
          return deal.locations.map((location, locationIndex) => {
            return {
              index: locationIndex,
              type: 'clo',
              id: deal.id,
              _raw: deal,
              title: deal.title,
              short_title: deal.title,
              locations: deal.locations,
              cashback_percentage: deal.cashBackPercentage / 100,
              image_url: deal['image-url'],
              merchant: {
                name: deal['advertiser-name'],
                latitude: location.latitude,
                longitude: location.longitude
              }
            };
          });
        }
      }).flat().filter(offer => offer);
      offers.forEach(offer => {
        if (!this.getRebateDetails(offer.id)) {
          this.cachedOffers.push(offer);
        }
      })
      return offers;
    } else if (this.ACCESS) {
      console.log('RESULTS', results)
      const offers = results.map(result => ({
          type: 'access',
          id: result.offer_key,
          _raw: result,
          title: result.title,
          short_title: '',
          discount_type: result.discount_type,
          discount_value: result.discount_value,
          price: result.discount_value,
          url: result.links.redeem_offer.instore,
          image_url: result.logo_url,
          description: result.offer_store.description,
          merchant: {
            name: result.offer_store.name,
            latitude: result.offer_store.physical_location.geolocation.lat,
            longitude: result.offer_store.physical_location.geolocation.lon,
            streetAddress1: result.offer_store.physical_location.street_address,
            city: result.offer_store.physical_location.city_locality,
            state: result.offer_store.physical_location.state_region,
            postalCode: result.offer_store.physical_location.postal_code,
            phoneNumber: result.offer_store.physical_location.phone_number,
            location: ''
    }
      }));
      offers.forEach(offer => {
        if (!this.getRebateDetails(offer.id)) {
          this.cachedOffers.push(offer);
        }
      })
      return offers;
    } else {
        return results.deals.map(dealWrapper => dealWrapper.deal);
    }
  }

  getRebateDetails(id: string) {
    return this.cachedOffers.find(offer => id === offer.id.toString());
  }

  getRebatesFromApi(url: string) {
    return this.httpClient.get(url).toPromise();
  }

  getRebates() {
    return this.restangular.one('v3').one('rebates').get().toPromise().then(result => {
      return this.reformatResults(result);
    });
  }

  getOffer(offerId: string) {
    return this.restangular.one('v3').one('rebates').one('offers', offerId).get().toPromise().then(result => result.offers[0]);
  }

  getRebatesByParams(params: any) {
    return this.restangular.one('v3').one('rebates').customPUT(params).toPromise().then(result => {
      return this.reformatResults(result);
    });
  }

  getRebatesByCategoryAndLocation(categorySlug: string, latitude: number, longitude: number, radius: number, query = '') {
    if (!categorySlug) {
      return this.getRebatesByLocation(latitude, longitude, radius, query);
    } else {
      return this.restangular.one('v3').one('rebates').one('categoryandlocation', categorySlug).one(latitude.toString()).one(longitude.toString()).one(radius.toString()).one(query).get().toPromise().then(result => {
        return this.reformatResults(result);
      });
    }
  }

  getRebatesByCategory(categorySlug: string) {
    return this.restangular.one('v3').one('rebates').one('category', categorySlug).get().toPromise().then(result => {
      return this.reformatResults(result);
    });
  }

  getRebatesByLocation(latitude: number, longitude: number, radius: number, query: string) {
    return this.restangular.one('v3').one('rebates').one('location').one(latitude.toString()).one(longitude.toString()).one(radius.toString()).one(query).get().toPromise().then(result => {
      return this.reformatResults(result);
    });
  }

  getAccessContent(url: string) {
    return this.restangular.one('v3').one('rebates').one('getaccesscontent').customPUT({url}).toPromise();
  }

}
